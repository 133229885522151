import React, { useEffect, useState } from 'react';
import { Button, Card, View, Flex, Alert, TextField} from '@aws-amplify/ui-react';
import { Amplify, API, graphqlOperation } from 'aws-amplify'
import { createMimic } from '../graphql/mutations'
import { listMimics } from '../graphql/queries'

const initialState = { name: '', description: '' }

export function Train() {

  const hiddenFileInput = React.useRef(null);
	const [selectedFile, setSelectedFile] = useState();
	const [isSuccess, setIsSuccess] = useState(false);
	const [isFilePicked, setIsFilePicked] = useState(false);
  const [formState, setFormState] = useState(initialState)

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value })
    setIsSuccess(false);
  }

  async function addMimic() {
    try {
      if (!formState.name || !formState.description) return
      const mimic = { ...formState }
      setFormState(initialState)
      await API.graphql(graphqlOperation(createMimic, {input: mimic}))
    } catch (err) {
      console.log('error creating mimic:', err)
    }
  }

  const handleClick = event => {
    hiddenFileInput.current.click();
    setIsSuccess(false);
  };


	const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsFilePicked(true);
	};


	const handleSubmission = () => {
    addMimic();
    setIsFilePicked(false);
    setIsSuccess(true);
	};


	return(
    <Flex justifyContent="center" alignContent="center" >
      <View width="100%" maxWidth="50rem" padding={{ base: 0, large: '2rem' }} >
        <Card variation="elevated" padding={{ base: 0, large: '2rem' }}>
          <input type="file" ref={hiddenFileInput} onChange={changeHandler} style={{display: 'none'}}/>
          <div>
            <Button onClick={handleClick}>Brows...</Button>
          </div>
          {isFilePicked ? (
            <div>
              <p>Filename: {selectedFile.name}</p>
              <p>Filetype: {selectedFile.type}</p>
              <p>Size in bytes: {selectedFile.size}</p>
            </div>
          ) : (
            <p>Select your labeled dataset.</p>
          )}
          <TextField
            onChange={event => setInput('name', event.target.value)}
            value={formState.name}
            label="Name"
            hasError={formState['name'].length<4}
          />
          <TextField
            onChange={event => setInput('description', event.target.value)}
            value={formState.description}
            label="Description"
            hasError={formState['description'].length<4}
          />
          {isSuccess ? (
            <Alert variation="success" heading="Upload Successful." margin={{ base: 0, large: '2rem' }}>
              <p>Your Model API is going to become available in *ESTIMATED_HOURS* hours.</p>
            </Alert>
          ) : (
            <div/>
          )}
          <div>
            <Button onClick={handleSubmission} disabled={!isFilePicked || formState['description'].length<4 || formState['name'].length<4} margin={{ base: 0, large: '2rem' }}>Submit</Button>
          </div>
        </Card>
      </View>
    </Flex>
	)


}











