/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMimic = /* GraphQL */ `
  mutation CreateMimic(
    $input: CreateMimicInput!
    $condition: ModelMimicConditionInput
  ) {
    createMimic(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateMimic = /* GraphQL */ `
  mutation UpdateMimic(
    $input: UpdateMimicInput!
    $condition: ModelMimicConditionInput
  ) {
    updateMimic(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteMimic = /* GraphQL */ `
  mutation DeleteMimic(
    $input: DeleteMimicInput!
    $condition: ModelMimicConditionInput
  ) {
    deleteMimic(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
