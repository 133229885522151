import React, { useEffect, useState } from 'react';
import { Flex, View, SearchField, Card, Loader, Heading } from '@aws-amplify/ui-react';
import { Amplify, API, graphqlOperation } from 'aws-amplify'
import { listMimics } from '../graphql/queries'

export function Dashboard() {

  useEffect(() => {
    fetchMimics()
  }, [])

    const [mimics, setMimics] = useState([])

    async function fetchMimics() {
      try {
        const mimicData = await API.graphql(graphqlOperation(listMimics))
        const mimics = mimicData.data.listMimics.items
        setMimics(mimics)
      } catch (err) { console.log('error fetching mimics') }
    }

  return (
    <Flex justifyContent="center" alignContent="center">
      <View width="100%" maxWidth="50rem" padding={{ base: 0, large: '2rem' }} >
        <SearchField label="search" placeholder="Search..." />
        {
          mimics.map((mimic, index) => (
            <div key={mimic.id ? mimic.id : index}>
              <Card variation="elevated" padding={{ base: 0, large: '1rem' }} margin={{ base: 0, large: '1rem' }}>
                <Loader variation="linear" />
                <Heading level={3} >
                  {mimic.name}
                </Heading>
                <p>{mimic.description}</p>
              </Card>
            </div>
          ))
        }
      </View>
    </Flex>
  );
}