import * as React from 'react';
import { Heading } from '@aws-amplify/ui-react';
export function Home() {
  return (
    <>
        <Heading level={1} margin={{ base: 0, large: '3rem' }}>Artificial Inteligence in Your Products, Just Like That.</Heading>
        <Heading level={3} margin={{ base: 0, large: '3rem' }}>
        Data in, a secure and scalabe API in production out.
        </Heading>
    </>
  );
}