/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-1",
    "aws_appsync_graphqlEndpoint": "https://ttl624yqy5b6rdjtohr6prtvii.appsync-api.us-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-gslvrzlagzdfxjusmyrcjk7wsm",
    "aws_cognito_identity_pool_id": "us-west-1:24c4e5fd-cad9-44cb-a79f-2987e6d18ea4",
    "aws_cognito_region": "us-west-1",
    "aws_user_pools_id": "us-west-1_kBQWmFtKZ",
    "aws_user_pools_web_client_id": "2v4cbisa4i02s74ibete0j3oub",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
